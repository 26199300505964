.sectionThree {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0rem !important;
}
.sectionThree_main {
  display: flex;
  font-size: 1.6rem;
}
.sectionThree_main_left {
  flex: 1;
  display: flex;
  /* justify-content: flex-end; */
  /* justify-content: flex-end; */
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.sectionThree_main_left_div {
  width: 35rem;
}
.sectionThree_main_right {
  flex: 1;
  display: flex;
  margin-left: 10rem;
  /* justify-content: center; */
  align-items: center;
}
.sectionThree_main_left_header {
  color: var(--subtext-color2);
  font-size: 3.5rem;
  margin-bottom: 2rem;
}
.sectionThree_main_left_para {
  font-size: 2rem;
  letter-spacing: 0.04rem;
  line-height: 2.5rem;
  color: (--subtext-color2);
}

.sectionThree_main_left_para_span {
  color: var(--primary-color);
  font-weight: 600;
}

.sectionThree_main_left_subHeader {
  position: relative;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  font-size: 2rem;
  margin-top: 2.5rem;
}
.sectionThree_main_left_subHeader_decor {
  position: relative;
  top: -0.3rem;
  z-index: -100;
  width: 20rem;
}
.sectionThree_main_left_bottom {
  margin-top: 3rem;
}
.sectionThree_main_left_bottom_botton {
  background-color: var(--primary-color);
  border: none;
  padding: 1.2rem 2rem;
  font-size: 1.8rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  letter-spacing: 0.03rem;
}

.sectionThree_main_left_bottom_botton:hover {
  transform: scale(1.1);
}
.sectionThree_main_right_container {
  width: 35rem;
  height: 49.1rem;
  border-radius: 1rem;

  box-shadow: -1px 2px 9px 0px rgba(130, 130, 130, 0.75);
  -webkit-box-shadow: -1px 2px 9px 0px rgba(130, 130, 130, 0.75);
  -moz-box-shadow: -1px 2px 9px 0px rgba(130, 130, 130, 0.75);
}
.sectionThree_main_right_container_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.sectionThree_main_left_subHeader_below {
  color: var(--subtext-color2);
  letter-spacing: 0.04rem;
  font-size: 1.6rem;
}

@media (max-width: 945px) {
  .sectionThree_main_right_container {
    width: 32rem;
    height: 45.1rem;
  }
}

@media (max-width: 850px) {
  .sectionThree_main_left_header {
    font-size: 3.3rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.8rem;
    letter-spacing: 0.02rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2.3rem;
  }
  .sectionThree_main_right {
    margin-left: 5rem;
  }
}

@media (max-width: 768px) {
  .sectionThree_main_right_container {
    width: 27rem;
    height: 38.1rem;
  }
  .sectionThree_main_left_header {
    font-size: 3.2rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2rem;
  }
  .sectionThree_main_right {
    margin-left: 2rem;
  }
}
@media (max-width: 689px) {
  .sectionThree_main_left_div {
    width: 32rem;
  }
  .sectionThree_main_right {
    margin-left: 3rem;
  }
}
@media (max-width: 665px) {
  .sectionThree_main_left_header {
    font-size: 3rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.6rem;
    letter-spacing: 0.02rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 1.8rem;
  }
  .sectionThree_main_left_subHeader_below {
    color: var(--subtext-color2);
    letter-spacing: 0.04rem;
    font-size: 1.5rem;
  }
  .sectionThree_main_left_div {
    width: 30rem;
  }
}
@media (max-width: 655px) {
  .sectionThree_main {
    flex-direction: column;
  }
  .sectionThree_main_left_div {
    width: 46rem;
  }
  .sectionThree_main_left_header {
    font-size: 3.5rem;
  }
  .sectionThree_main_left_para {
    font-size: 2rem;
    letter-spacing: 0.02rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2.3rem;
  }
  .sectionThree_main_left_subHeader_below {
    color: var(--subtext-color2);
    letter-spacing: 0.04rem;
    font-size: 1.8rem;
  }
  .sectionThree_main_right {
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-left: 0;
  }
  .sectionThree_main_right_container {
    width: 34rem;
    height: 44.1rem;
  }
}
@media (max-width: 589px) {
  .sectionThree_main_left_header {
    font-size: 3.3rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.8rem;
    letter-spacing: 0.02rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2.3rem;
  }
  .sectionThree_main_left_div {
    width: 40rem;
  }
}
@media (max-width: 466px) {
  .sectionThree_main_left_header {
    font-size: 3rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.8rem;
    letter-spacing: 0.02rem;
    line-height: 2.5rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2rem;
  }
  .sectionThree_main_left_div {
    width: 35rem;
  }
  .sectionThree_main_right_container {
    width: 29rem;
    height: 37.1rem;
  }
}

@media (max-width: 407px) {
  .sectionThree_main_left_header {
    font-size: 2.8rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.8rem;
    letter-spacing: 0.02rem;
    line-height: 2.5rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2rem;
  }
  .sectionThree_main_left_div {
    width: 33rem;
  }
}
@media (max-width: 376px) {
  .sectionThree_main_left_header {
    font-size: 2.6rem;
  }
  .sectionThree_main_left_para {
    font-size: 1.6rem;
    letter-spacing: 0.02rem;
    line-height: 2.5rem;
  }
  .sectionThree_main_left_subHeader_header {
    font-size: 2.2rem;
  }
  .sectionThree_main_left_subHeader_below {
    font-size: 1.6rem;
  }
}
@media (max-width: 365px) {
  .sectionThree_main_left_div {
    width: 32rem;
  }
  .sectionThree_main_left_header {
    font-size: 2.6rem;
  }

  .sectionThree_main_left_subHeader_header {
    font-size: 2rem;
  }
  .sectionThree_main_left_subHeader_below {
    font-size: 1.6rem;
  }
  .sectionThree_main_right_container {
    width: 26rem;
    height: 32.1rem;
  }
}
@media (max-width: 351px) {
  .sectionThree_main_left_div {
    width: 30rem;
  }
}
@media (max-width: 330px) {
  .sectionThree_main_left_div {
    width: 28rem;
  }
}
