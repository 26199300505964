.navbar_container,
.navbar_links,
.navbar_inner_wrapper,
.navbar_contents {
  display: flex;
  font-size: 1.6rem;
}

.navbar_container {
  justify-content: space-between;
  box-shadow: 0px 4px 4px -4px var(--shadow-color);
  font-weight: 400;
  white-space: nowrap;
  background-color: white;
}

.navbar_container.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

.navbar_inner_wrapper {
  justify-content: space-between;
  /* background-color: green; */
  margin: 0 12.2%;
  width: 100%;
}

.navbar_logo {
  width: auto;
  transition: transform 0.3s ease;
}

.navbar_logo:hover {
  transform: scale(1.06);
}

.navbar_links {
  margin: 0;
  height: 100%;
}

.navbar_item,
.navbar_btn {
  margin: 0 1.3rem;
}

.navbar_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
}

.navbar_item:hover {
  transform: scale(1.06);
}

.navbar_item_selected,
.active {
  color: var(--primary-color);
}

.navbar_btn_container {
  display: flex;
  align-items: center;
  /* width: 15%; */
}

.navbar_btn {
  background-color: var(--primary-color);
  padding: 0.65rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar_btn:hover {
  transform: scale(1.06);
}

.navbar_dropdown {
  display: flex;
  /* background-color: blue; */
}

.dropdown_menu {
  display: none;
  position: absolute;
  background-color: white;
  list-style: none;
  padding: 0.5rem 1rem;
  margin-top: 8rem;
  z-index: 1;
  border: 1px solid #ddd;
  border-top: 4px solid var(--primary-color);
  border-radius: 0.3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.dropdown_menu.show {
  display: block;
}

.dropdown_item {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 0.2px solid var(--shadow-color);
}

.navbar_contents .dropdown_item:hover {
  color: var(--primary-color);
}

.navbar_contents .dropdown_item {
  color: #000;
}

.navbar_contents .dropdown_item.active {
  color: var(--primary-color);
}

.navbar_contents .navbar_item.active,
.navbar_contents .navbar_item.active a,
.navbar_contents .dropdown_item.active a,
.navbar_contents .navbar_dropdown_item.active a {
  cursor: pointer;
  color: var(--primary-color) !important;
}

.primary_btn,
.primary_btn:visited,
.navbar_contents .navbar_item a,
.navbar_contents .navbar_item a:visited,
.navbar_contents .navbar_dropdown_item a,
.navbar_contents .navbar_dropdown_item a:visited,
.navbar_contents .dropdown_item a,
.navbar_contents .dropdown_item a:visited,
.navbar_contents a.navbar_btn,
.navbar_contents a.navbar_btn:visited {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.navbar_contents li {
  list-style: none;
}

.navbar_item a {
  text-decoration: none;
}

@media (max-width: 2000px) {
  .navbar_inner_wrapper {
    margin: 0 10.2%;
  }
}

@media (max-width: 1200px) {
  .navbar_inner_wrapper {
    margin: 0 10.2%;
  }

  .navbar_item {
    margin: 0 0.8rem;
  }
}

@media (max-width: 1100px) {
  .navbar_inner_wrapper {
    display: none;
  }
}
