.organizer_container {
    padding: 0 14% 0 13%;
}

.op_top_horizontal_line {
    width: 100%;
    height: 0.3px;
    background-color: var(--shadow-color);
}

.op_top_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6rem 0;
}

.op_top_title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
}

.colorfull_title {
    color: var(--primary-color);
}

.op_top_subtitle {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0.2rem 0;
    text-align: center;
}

.op_top_color_subtitle {
    font-weight: 500;
    color: var(--subtext-color1);
}

.op_team_container {
    margin: 8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

@media (max-width: 560px) {
    .op_top_title {
        font-size: 3rem;
    }

    .op_top_subtitle {
        font-size: 1rem;
    }
}