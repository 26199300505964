.alogin_container,
.alogin_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alogin_container {
    height: 90vh;
}

.alogin_header {
    font-size: 3rem;
}

.alogin_email,
.alogin_password {
    width: 40rem;
    height: 3rem;
    padding: 1rem;
}

.alogin_email {
    margin: 1rem;
}

.alogin_password {
    margin-bottom: 1rem;
}

.alogin_show_password_div {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.alogin_show_password_checkbox{
    margin-right: 0.6rem;
}

.alogin_button {
    border: none;
    width: 10rem;
    font-size: 1.8rem;
}