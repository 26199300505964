.footer_container {
  padding: 0 11% 0 13%;
  background-image: url("./gradients/curves.svg"),
    url("./gradients//Decore.svg");
  background-position: left center, bottom left;
  background-repeat: no-repeat;
  background-size: 50rem auto, 25rem auto;
  /* background-color: blue; */
  /* margin-top: 8rem; */
  font-size: 1.6rem;
}

.footer_top_section {
  padding: 1rem 0;
  box-shadow: 0px -2px 2px -2px var(--shadow-color);
}

.footer_top_section,
.footer_top_icons_div {
  display: flex;
  justify-content: center;
}

.footer_top_left_logo {
  transition: transform 0.3s ease;
}

.footer_top_left_logo:hover {
  transform: scale(1.05);
}

.footer_top_left {
  width: 40%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  /* background-color: red; */
}

.footer_top_right {
  width: 60%;
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6.5rem 0;
}

.footer_top_title {
  font-family: var(--footer-font-family);
  font-weight: 700;
  font-size: 3rem;
}

.footer_top_btn {
  margin: 1rem 0;
}

.footer_top_icon {
  margin: 0 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer_top_icon:hover {
  transform: scale(1.2);
}

.footer_bottom {
  display: flex;
  height: 100%;
  padding: 1rem 1.5rem;
  box-shadow: 0px 2px 2px -2px var(--shadow-color),
    0px -2px 2px -2px var(--shadow-color);
}

.footer_title {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: var(--footer-font-family);
}

.footer_items,
.footer_items a {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  color: var(--subtext-color);
  font-family: var(--secondary-font-family);
  transition: transform 0.3s ease;
  cursor: pointer;
  text-decoration: none
}

.footer_items:hover {
  transform: scale(1.03);
}

.footer_contact_items {
  color: black;
  word-break: break-word;
}

.footer_icons {
  margin: 0 0.4rem;
  font-size: 1.3rem !important;
}

.footer_left_div {
  display: flex;
  width: 52rem;
  padding: 0.5rem;
  justify-content: space-evenly;
}

.footer_vertical_line {
  height: auto;
  width: 0.5px;
  background-color: var(--shadow-color);
}

.footer_section {
  display: flex;
  flex-direction: column;
  margin: 0.2rem 1rem;
}

.footer_Quick_link_section {
  margin-right: 5rem;
}

.footer_right_div {
  background-color: var(--primary-color);
  border-radius: 0.3rem;
  margin: 0 2rem;
  padding: 1.4rem 1rem;
  width: 28rem;
  overflow-wrap: break-word;
}

@media (max-width: 1200px) {
  .footer_container {
    padding: 0 10.2%;
  }

  .footer_left_div {
    width: 50rem;
  }
}

@media (max-width: 950px) {
  .footer_top_title {
    /* font-weight: 700; */
    font-size: 2.3rem;
    /* background-color: red; */
    text-align: center;
  }

  .footer_left_div {
    width: 42rem;
  }
}

@media (max-width: 770px) {
  .hide {
    display: none;
  }

  .footer_container {
    background-image: url("./gradients/curves.svg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 50rem auto;
    font-size: 1.6rem;
  }

  .footer_top_section {
    padding: 2.3rem 0;
    flex-direction: column;
    /* background-color: red; */
    align-items: center;
  }

  .footer_top_left {
    padding: 0;
  }

  .footer_top_right {
    padding: 0;
    margin-top: 4rem;
    width: 100%;
  }

  .footer_left_div {
    width: 34rem;
  }

  .footer_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.6rem 0;
    /* background-color: red; */
  }

  .footer_Quick_link_section {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 770px) {
  .footer_bottom {
    padding: 0;
  }

  .footer_left_div {
    width: auto;
  }
}
