.dt_trigger_btn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 4rem !important;
}

.dt_edit_btb {
    transition: transform 0.3s ease;
}

.dt_edit_btn:hover {
    transform: scale(1.2);
}

.dt_pp_input {
    width: 80%;
    margin-top: 1rem;
}