/* .container {
    max-width: 800px;
    margin: 0 auto;
} */

.blog-post {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-content {
    font-family: Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
}

.post-content p {
    margin-bottom: 1rem;
}

.post-content ol {
    padding-left: 2rem;
}

.post-content ol li {
    margin-bottom: 0.5rem;
}

.terms strong {
    font-weight: bold;
}

.terms a {
    color: #007bff;
    text-decoration: none;
}

.terms a:hover {
    text-decoration: underline;
}

@media (max-width: 430px) {
    .blog-post {
        background-color: inherit;
        padding: 0rem;
        border-radius: 0px;
        box-shadow: none;
    }
}