:root {
  /* colors */
  --primary-color: #ffb901;
  --submit-color: #4caf50;
  --cancel-color: #dd1919;
  --text-color: #2f327d;
  --subtext-color: #62626f;
  --subtext-color1: #797979;
  --subtext-color2: #333333;
  --subtext-color3: #33333380;
  /* used in organizer page */
  --subtext-color4: #415EB6;
  --header-color: #181e4b;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --semi-transparent-color: rgba(36, 30, 31, 0.52);
  --white-color: #ffffff;
  /* font family */
  --primary-font-family: "Sora", sans-serif;
  --secondary-font-family: "Poppins", "Sora", sans-serif;
  --footer-font-family: "Kufam", "Sora", sans-serif;
}

@media screen and (min-width: 1200px) {
  .faqcontainer {
    align-items: center;
    font-family: var(--secondary-font-family);
  }
  .faqcontainer .faqtitle {
    margin-top: 3%;
    display: grid;
    font-family: var(--primary-font-family);
    justify-items: center;
    align-items: center;
  }
  .faqcontainer .faqtitle .FAQ {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 5rem;
    margin: 0.5rem;
  }
  .faqcontainer .faqtitle .FAQ span span {
    color: var(--primary-color);
  }
  .faqcontainer .faqtitle .bootcamp {
    font-size: 1.5rem;
    color: var(--subtext-color1);
  }
  .faqcontainer .faqtitle .date {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0.2rem 0;
    margin-bottom: 4rem;
  }
  .faqcontainer .faqtitle .op_top_horizontal_line {
    width: 100%;
    height: 0.3px;
    background-color: var(--shadow-color);
    margin-bottom: 4rem;
  }
  .faqcontainer .yellowlayout {
    background-color: var(--primary-color);
    width: 100%;
    height: auto;
  }
  .faqcontainer .yellowlayout .abt_bootcamp {
    display: grid;
  }
  .faqcontainer .yellowlayout .abt_bootcamp .btcmp_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
  }
  .faqcontainer .yellowlayout .application {
    display: grid;
  }
  .faqcontainer .yellowlayout .application .app_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
  }
  .faqcontainer .yellowlayout .registration {
    display: grid;
  }
  .faqcontainer .yellowlayout .registration .reg_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
  }
}
@media screen and (max-width: 1200px) {
  .faqcontainer {
    align-items: center;
    font-family: var(--secondary-font-family);
  }
  .faqcontainer .faqtitle {
    margin-top: 3%;
    display: grid;
    font-family: var(--primary-font-family);
    justify-items: center;
    align-items: center;
  }
  .faqcontainer .faqtitle .FAQ {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 5rem;
    margin: 0.5rem;
  }
  .faqcontainer .faqtitle .FAQ span span {
    color: var(--primary-color);
  }
  .faqcontainer .faqtitle .bootcamp {
    font-size: 1.5rem;
    color: var(--subtext-color1);
  }
  .faqcontainer .faqtitle .date {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0.2rem 0;
    margin-bottom: 4rem;
  }
  .faqcontainer .faqtitle .op_top_horizontal_line {
    width: 100%;
    height: 0.3px;
    background-color: var(--shadow-color);
    margin-bottom: 4rem;
  }
  .faqcontainer .yellowlayout {
    background-color: var(--primary-color);
    width: 100%;
    height: auto;
  }
  .faqcontainer .yellowlayout .abt_bootcamp {
    display: grid;
  }
  .faqcontainer .yellowlayout .abt_bootcamp .btcmp_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }
  .faqcontainer .yellowlayout .application {
    display: grid;
    width: 100%;
  }
  .faqcontainer .yellowlayout .application .app_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }
  .faqcontainer .yellowlayout .registration {
    display: grid;
    width: 100%;
  }
  .faqcontainer .yellowlayout .registration .reg_title {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: var(--subtext-color2);
    text-align: center;
  }
}/*# sourceMappingURL=FaqPage.css.map */