.drawer_container {
  display: none;
  margin: 0 8%;
  width: 100%;
}

.drawer_menu_icon {
  font-size: 4rem !important;
  cursor: pointer;
}

.open_drawer_container {
  height: 100vh;
  width: 100vw;
  background-color: var(--semi-transparent-color);
  display: flex;
  justify-content: space-between;
}

.open_drawer_inner_container {
  height: 100vh;
  width: 90rem;
  padding: 0 1.2rem;
  background-color: white;
}

.drawer_remaining_container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: end;
}

.drawer_close_icon {
  margin-top: 1rem;
  margin-right: 3rem;
  font-size: 4rem !important;
  color: white !important;
  cursor: pointer;
}

.d_expand_icon {
  font-size: 2.3rem !important;
  margin-left: 1rem;
}

.drawer_logo_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.drawer_contents {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  align-items: center;
  margin-top: 0.5rem;
}

.drawer_links {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawer_item {
  width: 100%;
  padding: 1.2rem;
  display: flex;
  align-items: center;
}

.drawer_dropdown_item {
  font-size: 1.6rem;
  margin: 1rem 0;
}

.drawer_contents .drawer_item.active,
.drawer_contents .drawer_item.active a,
.drawer_contents .drawer_dropdown_item.active a {
  cursor: pointer;
  color: var(--primary-color);
}

.hide_dropdown {
  display: none;
}

.drawer_horizontal_line {
  width: 100%;
  height: 0.2px;
  background-color: var(--shadow-color);
}

.drawer_item a,
.drawer_dropdown_item a,
.drawer_item a:visited,
.drawer_dropdown_item a:visited {
  color: #000;
  text-decoration: none;
}

.drawer_contents li {
  list-style: none;
}

.drawer_item a {
  text-decoration: none;
}


@media (max-width: 1100px) {
  .drawer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
