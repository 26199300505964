.ag_about_geb_container {
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ag_top_title {
    width: 85rem;
    text-align: center;
}