.editableHomePage_container {
  position: relative;
}

.ehp_homebanner_container {
  position: relative;
}

.ehp_edit_btn {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  background-color: var(--primary-color);
  color: black;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  margin: 0.8rem 1.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.ehp_edit_popup {
  height: 100vh;
  width: 100vw;
  background-color: var(--semi-transparent-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ehp_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ehp_inner_container {
  height: 70vh;
  width: 70vw;
  background-color: white;
  border-radius: 0.8rem;
}

.ehp_form_container_header {
  font-size: 4rem;
}

.ehp_form_top {
  display: flex;
  margin: 0.2rem 1rem;
  height: 60%;
  width: 80%;
  justify-content: center;
  align-items: top;
  /* background-color: red; */
}

.ehp_form_left,
.ehp_form_right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ehp_form_left {
  width: 45%;
}

.ehp_form_right {
  width: 55%;
}

.ehp_vertical_line {
  width: 1px;
  height: 80%;
  background-color: var(--shadow-color);
  margin-right: 2rem;
}

.ehp_label {
  width: 80%;
  margin-top: 1.5rem;
  font-size: 1.4rem;
}

.ehp_input_file_container {
  display: flex;
  flex-direction: column;
}

.ehp_input_field {
  margin: 0.5rem;
  width: 80%;
  padding: 1rem;
}

.ehp_form_bottom {
  display: flex;
  margin-top: 1rem;
}

.ehp_cancel,
.ehp_submit {
  border: 0;
  margin: 0 0.5rem;
}

.ehp_cancel {
  background-color: red;
  color: white;
}
.button_edit {
  background-color: var(--primary-color);
  font-size: 1.6rem;
  font-weight: 600px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.delete_editable {
  background-color: red;
  color: white;
  padding: 0.5rem 1rem;
  margin-left: 2rem;
  border: non;
  font-size: 1.6rem;
}
