.pg_bi_container {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    margin: 6rem 1rem;
    position: relative;
    white-space: pre-wrap;
}

.pg_bi_reverse_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: start;
    margin: 3rem 1rem;
    position: relative;
    white-space: pre-wrap;
}

.pg_bi_avatar {
    border-radius: 0.8rem;
    height: 23rem;
    width: 20rem;
    object-fit: contain;
    box-shadow: 3px 3px 8px 5px rgba(0, 0, 0, 0.08);
}

.pg_bi_content_container {
    width: 40rem;
}

.pg_bit_title {
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.pg_bit_description {
    font-size: 1.2rem;
    font-weight: 300;
}

@media (max-width: 1000px) {
    .pg_bi_container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .pg_bi_reverse_container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .pg_bi_content_container {
        margin-top: 1rem;
        width: 80%;
    }
}

@media (max-width: 400px) {
    .pg_bi_avatar {
        border-radius: 0.8rem;
        height: 19rem;
        width: 16rem;
        object-fit: contain;
        box-shadow: 3px 3px 8px 5px rgba(0, 0, 0, 0.08);
    }

    .pg_bi_content_container {
        margin-top: 1rem;
        width: 100%;
    }
}