.anb_main {
    margin: 4rem 10rem;
}

.anb_form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.anb_form_top_inputs {
    display: flex;
    align-items: center;
    margin: 2rem 0;
}

.anb_input_label {
    font-size: 1.6rem;
    margin: 0.5rem;
}

.anb_show_contents_div {
    margin: 2.5rem 0;
}

.anb_form_bottom_inputs {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.anb_new_btn_container {
    display: flex;
}

.edit_popup_btn_anb {
    font-size: 1.5rem;
    border-radius: 0.3rem;
    background-color: rgb(201, 201, 201);
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 0 2rem;
    cursor: pointer;
}