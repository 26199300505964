@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

code {
  font-family: "Sora", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary_btn {
  background-color: var(--primary-color);
  padding: 0.65rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.primary_btn:hover {
  transform: scale(1.06);
}

:root {
  /* colors */
  --primary-color: #ffb901;

  --submit-color: #4caf50;
  --cancel-color: #dd1919;
  --text-color: #2f327d;
  --subtext-color: #62626f;
  --subtext-color1: #797979;
  --subtext-color2: #333333;
  --subtext-color3: #33333380;
  /* used in organizer page */
  --subtext-color4: #415EB6;

  --header-color: #181e4b;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --semi-transparent-color: rgba(36, 30, 31, 0.52);
  --white-color: #ffffff;
  --organizer-text: #5E6282;

  /* font family */
  --primary-font-family: "Sora", sans-serif;
  --secondary-font-family: "Poppins", "Sora", sans-serif;
  --footer-font-family: "Kufam", "Sora", sans-serif;
}

.img_with_bg_color {
  background-color: #ccc;
}

.primary_button {
  background-color: var(--primary-color);
  border: none;
  padding: 1.2rem 2rem;
  font-size: 1.8rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  letter-spacing: 0.03rem;
}

.primary_button:hover {
  transform: scale(1.1);
}


.d-block {
  display: block !important;
}

.page__header {
  text-align: center;
  padding: 7.625rem 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.page__header::after {
  content: '';
  height: 1px;
  background: #cfcfcf;
  max-width: 1129px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.page__header__bg {
  display: none;
  position: absolute;
  inset: 0;
  z-index: -1;
}

.page__header h1 {
  text-transform: uppercase;
  font-size: 2.7rem;
  margin-bottom: 3.5625rem;
}

.page__header h1 mark {
  color: var(--primary-color);
  background: none;
}

.page__header .seperator {
  max-width: 340px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5.6875rem;
}

.page__header .seperator img {
  width: 100%;
  height: auto;
}

.page__header em {
  font-weight: 500;
  font-size: 2.0819rem;
  display: block;
}

.page__header em::before {
  content: '"';
  font-size: 5.208125rem;
  line-height: 0;
  display: inline-block;
  vertical-align: top;
  margin: 15px 8px 0 0;
}

.page__header em span {
  color: var(--primary-color);
}

.page__header__bg img {
  max-width: 100%;
  height: auto;
  position: absolute;
}

.page__header__bg .top-decor {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.page__header__bg .left-decor {
  max-width: 250px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
}

.page__header__bg .right-decor {
  max-width: 250px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 0;
}

.page__header strong {
  font-weight: 600;
  font-size: 1.5625rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.75rem;
}

.page__header strong span {
  color: #807f7f;
  font-weight: 300;
  font-size: 1.1719rem;
  display: block;
}

.page__header time {
  display: block;
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 3.5rem;
}

.page__header .btn__holder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.section {
  --base-color: #ffba00;

  color: #454545;
  font-size: 16px;
  line-height: 1.7;
  padding: 4rem 2.5rem;
  overflow-y: clip;
  position: relative;
  z-index: 1;
}

.section__bg {
  display: none;
  position: absolute;
  inset: 0;
  z-index: -1;
}

.section__bg img {
  max-width: 100%;
  height: auto;
  position: absolute;
}

.section__bg .glow {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.section__bg .quote {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}

.section__bg .mark {
  top: 0;
  left: 0;
}

.section h2 {
  color: #000;
  text-transform: uppercase;
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
}

.section h2 span {
  color: var(--base-color);
}

.section h2 mark {
  padding-bottom: 10px;
  background: url('./assets/home-decor-05.png') no-repeat 50% 100%;
}

.section .wrap {
  text-align: center;
  line-height: 1.7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section .wrap h3 {
  font-weight: 600;
  font-size: 1.85rem;
  margin-bottom: 1.5rem;
}

.section .wrap h3 mark {
  background: none;
  color: var(--base-color);
  display: block;
}

.section .wrap .text__holder p {
  margin-bottom: 2rem;
}

.section .wrap .text__holder a:not(.primary_btn) {
  text-decoration: none;
  color: var(--base-color);
}

.section .holder>p {
  margin-bottom: 2rem;
}

.section .image+.holder {
  margin: 0;
}

.section .image {
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.section .image img {
  width: 100%;
  height: auto;
}

.section .image .decor {
  width: 92px;
  position: absolute;
  bottom: 10%;
  right: -5%;
}

.section blockquote {
  text-align: left;
  position: relative;
  padding-left: 2.125rem;
}

.section blockquote .name {
  color: #000;
  font-weight: 600;
  font-size: 2.1rem;
  display: block;
  margin-bottom: 2rem;

  span {
    color: var(--base-color);
  }
}

.section blockquote::before {
  content: '';
  width: 8px;
  height: 130px;
  background: var(--base-color);
  position: absolute;
  top: 0;
  left: 0;
}

.section blockquote q {
  display: block;
  margin-bottom: 1rem;
}

.section blockquote q::before,
.section blockquote q::after {
  display: none;
}

.section blockquote cite {
  font-style: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 3rem;
}

.section blockquote cite figure {
  -ms-flex-preferred-size: 120px;
  flex-basis: 120px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.section blockquote cite figure img {
  max-width: 100%;
  height: auto;
}

.section blockquote cite strong {
  color: #000;
  font-size: 2.1rem;
  line-height: 1.2;
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
}

.section blockquote cite strong span {
  color: var(--base-color);
}

.section blockquote cite p {
  margin-bottom: 1rem;
}

.section blockquote cite em {
  background: var(--base-color);
  color: #000;
  font-weight: 600;
  font-style: normal;
  display: block;
  padding: 15px 10px;
  border-radius: 6px;
}

.section blockquote cite em::before {
  content: '-';
  margin-right: 0.25rem;
}

.section .btn__holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 2.5rem;
}

.section .btn__holder .btn {
  font-size: 1.5rem;
}

.section .text__holder {
  text-align: center;
}

.partners {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  gap: 2rem
}

.partners img {
  max-width: 70%;
  height: auto;
  vertical-align: top;
}

.list__item {
  background: #f7f7f7;
  font-size: 1.3538rem;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2.75rem 2.4375rem;
  margin-bottom: -10px;
  border-radius: 10px;
}

.list__item .icon__holder {
  background: var(--primary-color);
  -ms-flex-preferred-size: 50px;
  flex-basis: 50px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  aspect-ratio: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 21px;
}

.list__item .icon__holder img {
  max-width: 27px;
  aspect-ratio: 1;
  -webkit-filter: invert(1);
  filter: invert(1);
  -o-object-fit: contain;
  object-fit: contain;
}

.list__item .list__item__detail h3 {
  font-size: 1.4594rem;
}

.list__item .list__item__detail h3,
.list__item .list__item__detail p {
  margin-bottom: 0;
}

.areas {
  max-width: 900px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
}

.areas li {
  width: calc(50% - 20px);
  aspect-ratio: 1.3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.areas li:hover {
  background: var(--primary-color);
  color: #fff;
}

.areas li:hover img {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.areas li img {
  max-width: 51px;
  -webkit-filter: invert(80%) sepia(25%) saturate(7035%) hue-rotate(2deg) brightness(107%) contrast(104%);
  filter: invert(80%) sepia(25%) saturate(7035%) hue-rotate(2deg) brightness(107%) contrast(104%);
}

.portfolio-growth {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 45px 12px;
  margin-right: -12px;
}

.portfolio-growth-item {
  width: calc(50% - 12px);
  font-size: 14px;
  padding: 2rem 1rem;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid var(--primary-color);
  border-radius: 10px;
}

.portfolio-growth-item strong {
  font-size: 1.3rem;
  display: block;
  margin-bottom: 0.75rem;
}

.portfolio-growth .portfolio-growth-item p {
  margin-bottom: 0;
}

.eligibility__list {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.eligibility {
  background: #f8f7f7;
  text-align: center;
  border-radius: 10px;
  overflow: clip;
}

.eligibility__list .eligibility h3 {
  background: var(--base-color);
  font-size: 1.7719rem;
  padding: 1rem 0.25rem;
  margin-bottom: 0;
}

.eligibility__list .eligibility p {
  padding: 3.625rem 1.1875rem;
  margin-bottom: 0;
}

.tab__section {
  width: 100%;
  margin: 0 40px;
  border: 1px solid #000;
}

.tab__list {
  list-style: none;
}

.tab__list li {
  flex: 25%;
  flex-grow: 1;
  flex-shrink: 0;
}

.tab__list a {
  color: #000;
  height: 100%;
  font-weight: 600;
  font-size: 1.3rem;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.75rem 1rem;
  border-bottom: 1px solid #000;
  position: relative;
}

.tab__list a::before {
  border-style: solid;
  border-width: 11px 10px 0;
  border-color: var(--base-color) transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tab__list .active a {
  background: var(--base-color);
  border: none;
}

.tab__block {
  padding: 3.125rem 2.5625rem;
}

.tab__block ul {
  list-style: none;
}

.tab__block li {
  margin-bottom: 2rem;
}

.tab__block li:last-child {
  margin-bottom: 0;
}

.tab__block li:last-child strong {
  padding-bottom: 0;
}

.tab__block li:first-child strong::after {
  top: 20%;
}

.tab__block li:last-child strong::after {
  bottom: calc(100% - 6px);
}

.tab__block li strong::before {
  background: #fff;
  width: 15px;
  aspect-ratio: 1;
  border: 2px solid var(--base-color);
  border-radius: 50%;
  right: 10px;
  top: 6px;
}

.tab__block li strong::after {
  background: var(--base-color);
  width: 2px;
  top: 0;
  right: 18px;
  bottom: 0;
  z-index: -1;
}

.tab__block strong {
  width: 18rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  position: relative;
  z-index: 1;
}

.tab__block span {
  flex-grow: 1;
}

.faq__section {
  background: var(--base-color);
  padding: 10.7rem 2.5rem;
  margin: 0 -2.5rem;
}

/* Accordion */
.accordion {
  max-width: 55.7rem;
  font-size: 1.5rem;
  margin: 0 auto;
}

.accordion__block {
  background: #fff;
  padding: 2rem;
  margin-bottom: 3rem;
  border-radius: 4px;
}

.accordion__block:last-child {
  margin-bottom: 0;
}

.accordion--active .accordion__slide {
  display: block;
}

.accordion__opener {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion__slide {
  display: none;
}

.corporate .section {
  overflow: visible;
}

.masonry {
  column-count: 2;
  gap: 10px;
  border-radius: 0 120px 0 120px;
  overflow: hidden;
}

.masonry .masonry__item {
  margin-bottom: 10px;
}

.masonry__wrapper {
  overflow: hidden;
  margin-bottom: -10px;
}

.masonry img {
  max-width: 100%;
  vertical-align: top;
  object-fit: cover;
}

.corporate {
  overflow: hidden;
}

.impact {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-right: -15px;
}

.impact__item {
  background: var(--base-color);
  max-width: calc(50% - 15px);
  flex-basis: calc(50% - 15px);
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.impact__item__image {
  background: #fff;
  flex-basis: 5.1rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 50%;
}

.impact__item__image img {
  max-width: 70%;
  height: auto;
  vertical-align: top;
}

.impact__item__detail {
  text-align: center;
  font-size: 14px;
  flex-grow: 1;
}

.impact__item__detail strong {
  font-weight: 600;
  display: block;
}

/*
* Tablet
*/
@media screen and (min-width: 768px) {
  .page__header .btn__holder {
    gap: 1.5rem;
  }

  .page__header__bg {
    display: block;
  }

  .section:nth-of-type(odd) .image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .section__bg {
    display: block;
  }

  .section .wrap {
    width: 100%;
    max-width: 1442px;
    text-align: left;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
  }

  .section .holder {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
  }

  .section .holder .image__holder img {
    max-width: 100%;
    height: auto;
  }

  .section .image+.holder {
    max-width: 550px;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .section .image {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  .section h2 {
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  .section blockquote .name {
    font-size: 2.6rem;
  }

  .section blockquote cite strong {
    font-size: 2.6rem;
  }

  .section .btn__holder .btn {
    font-size: 1.5rem;
  }

  .section blockquote cite {
    gap: 4.1rem;
  }

  .section blockquote cite figure {
    -ms-flex-preferred-size: 140px;
    flex-basis: 140px;
  }

  .partners {
    gap: 1.5rem;
    padding-top: 6rem;
  }

  .portfolio-growth-item strong {
    font-size: 1.8rem;
  }

  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .list__item {
    width: calc(50% - 23px);
    margin: -10px 11.5px 0;
  }

  .list__item .icon__holder {
    -ms-flex-preferred-size: 80px;
    flex-basis: 80px;
    margin-right: 30px;
  }

  .list__item .icon__holder img {
    max-width: 54px;
  }

  .areas li {
    width: calc(25% - 20px);
  }

  .portfolio-growth {
    gap: 45px 24px;
    margin-right: -24px;
  }

  .portfolio-growth-item {
    width: calc(33.33% - 24px);
    padding: 4.4375rem 3.125rem 3.5rem;
  }

  .eligibility {
    width: calc(33.33% - 18px);
  }

  .tab__list {
    display: flex;
  }

  .tab__list li+li {
    border-left: 1px solid #000;
  }

  .tab__list .active a::before {
    content: '';
  }

  .tab__block ul {
    font-size: 18px;
  }

  .tab__block li {
    display: flex;
    margin-bottom: 0;
  }

  .tab__block li strong::before,
  .tab__block li strong::after {
    content: '';
    position: absolute;
  }

  .tab__block li strong {
    padding: 0 4rem 3rem 0;
  }

  .accordion__opener {
    font-size: 20px;
  }

  .masonry {
    column-count: 4;
  }

  .masonry img {
    height: 245px;
  }

  .long-image img {
    height: 500px;
  }

  .impact__item {
    max-width: calc(25% - 15px);
    flex-basis: calc(25% - 15px);
    padding: 15px;
  }

  .impact__item__detail {
    font-size: 13px;
  }

  .eligibility {
    font-size: 14px;
  }
}

/*
* Desktop
*/
@media screen and (min-width: 992px) {
  .page__header .btn__holder {
    gap: 4.75rem;
  }

  .page__header h1 {
    font-size: 4rem;
  }

  .section {
    padding: 8rem 0;
  }

  .section .image+.holder {
    -ms-flex-preferred-size: 32.8125%;
    flex-basis: 32.8125%;
  }

  .section blockquote .name {
    font-size: 2.1rem;
  }

  .section blockquote cite strong {
    font-size: 2.1rem;
  }

  .list__item .icon__holder {
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    margin-right: 49px;
  }

  .areas {
    gap: 40px;
  }

  .areas li {
    width: calc(25% - 40px);
  }

  .impact__item {
    padding: 20px;
  }

  .impact__item__image {
    flex-basis: 8rem;
  }

  .impact__item__detail {
    font-size: 18px;
  }

  .impact {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    padding: 10rem 0;
  }

  .impact {
    width: 100%;
    margin-right: 0;
  }

  .impact__item__detail {
    font-size: 21px;
  }

  .eligibility {
    font-size: 18px;
  }
}

@media (max-width: 1990px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 62.5%;
  }
}